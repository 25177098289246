import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import theme from "assets/styles/theme"
import PropTypes from "prop-types"

const StyledLink = styled(Link)`
    display: block;
    position: relative;
    width: 250px;
    padding: 0;
    text-align: center;
    line-height: 50px;
    color: white;
    border-radius: 30px;
    transition: all 0.2s;
    background: ${theme.color.mainBlueColor};
    font-size: ${theme.font.size.xs};

    &:hover {
        text-decoration: none;
        transform: scale(1.1,1.1);
    }

    ${theme.mq.small} {
        margin: 0 auto;
    }
`

const ButtonLink = props => {
    return <StyledLink to={props.to} className={props.className} state={props.state} type={props.type}>
        {props.title}
    </StyledLink>
}

ButtonLink.defaultProps = {
    to: `/`,
    title: `Przycisk`,
    type: ``,
}

ButtonLink.propTypes = {
    to: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
}

export default ButtonLink
