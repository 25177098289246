import React from "react"
import MainTemplate from "templates/Main/MainTemplate";
import ChangePasswordScreen from "components/Pages/ChangePasswordScreen";

const ChangePasswordPage = (props) => (
    <MainTemplate {...props}>
        <ChangePasswordScreen {...props}/>
    </MainTemplate>
);

export default ChangePasswordPage;