import React from 'react';
import styled from "styled-components";
import SectionHeader from "components/SectionHeader";
import {graphql, StaticQuery} from "gatsby";
import Button from "components/Button";
//TODO: Snackbar do zewnętrznego komponentu
import Snackbar from '@material-ui/core/Snackbar';
import axios from "axios";
import ButtonLink from "components/ButtonLink";
import Loader from "components/Loader";
import {translate} from "services/translate";

import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import TextInput from "components/Forms/TextInput";

const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  margin: 0 auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  padding-bottom: 90px;
  background: url(${({background}) => (background ? background : '')})
      no-repeat -470px top fixed;
  background-size: 280% auto; // Fix for mobile - resizing image when keyboard is shown
  justify-content: center;
  position: relative;

  &:before {
    background: -webkit-linear-gradient(bottom,rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
    position: absolute;
    width: 100%;
    min-height: 100vh;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
  }
`;

const FormWrapper = styled.div`
  margin: 3rem 0;
  position: relative;

  .input-wrapper {
    margin: 2rem 0;
  }

  .error {
    text-align: center;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

class ChangePasswordScreen extends React.Component {
    changePasswordForm = FormBuilder.group({
        password: ["", [Validators.required, Validators.minLength(8)]],
    });

    constructor(props) {
        super(props);

        this.state = {
            error: "",
            showSnackbar: false,
            isPasswordChanged: false,
            loading: false,
        };
    }

    handleSubmit = event => {
        const queryString = require('query-string');
        const actionCode = queryString.parse(this.props.location.search).oobCode;
        const self = this;

        event.preventDefault();

        const firebase = require("firebase");
        const auth = firebase.auth();
        const database = firebase.firestore();

        this.setState({
            loading: true
        });

        auth.verifyPasswordResetCode(actionCode).then(function (email) {
            auth.confirmPasswordReset(actionCode, self.changePasswordForm.value.password).then(function (response) {
                auth.signInWithEmailAndPassword(email, self.changePasswordForm.value.password).then((response) => {
                    var document = database.collection("users").doc(response.user.uid);
                    document.get().then(function (doc) {
                        if (doc.exists) {
                            const userFirebaseData = doc.data();

                            if (!userFirebaseData.profileId) {
                                console.log("Brak profilu?!")
                            } else {
                                axios
                                    .patch(`${process.env.API_URL}/profiles/${userFirebaseData.profileId}?access-token=${userFirebaseData.accessToken}`, {
                                        password: self.changePasswordForm.value.password,
                                    })
                                    .then(response => {
                                        self.setState({
                                            error: {},
                                            showSnackbar: true,
                                            isPasswordChanged: true,
                                            loading: false
                                        });
                                    })
                                    .catch(error => {
                                        self.setState({
                                            loading: false,
                                            error: error
                                        });
                                    });
                            }
                        } else {
                            self.setState({
                                loading: false,
                                error: {
                                    message: 'Wystąpił problem. Prosimy o kontakt z obsługą techniczną.'
                                }
                            });
                        }
                    }).catch(function (error) {
                        self.setState({
                            loading: false,
                            error: {
                                code: error.code,
                                message: translate(error.code)
                            }
                        });
                    });
                });
            }).catch(function (error) {
                self.setState({
                    loading: false,
                    error: {
                        code: error.code,
                        message: translate(error.code)
                    }
                });
            });
        }).catch(function (error) {
            self.setState({
                loading: false,
                error: {
                    code: error.code,
                    message: translate(error.code)
                }
            });
        });
    };

    handleSnackbarClose = () => {
        this.setState({
            showSnackbar: false,
        })
    };

    render() {
        if (typeof window !== "undefined") {
            return (
                <StaticQuery
                    query={graphql`
                {
                    loginBackground: file(relativePath: { eq: "login.jpg" }) {
                      childImageSharp {
                        fluid(maxWidth: 1920) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                }
                `}
                    render={data => (
                        <Wrapper background={data.loginBackground.childImageSharp.fluid.src}>
                            {this.state.loading &&
                            <Loader/>
                            }

                            <SectionHeader header="Zmień hasło" center="true"/>
                            <FormWrapper>

                                {!this.state.isPasswordChanged &&
                                <div>
                                    <FieldGroup
                                        control={this.changePasswordForm}
                                        render={({get, invalid}) => (
                                            <form onSubmit={this.handleSubmit}>

                                                <FieldControl
                                                    name="password"
                                                    render={TextInput}
                                                    meta={{
                                                        label: "Nowe hasło",
                                                        type: "password",
                                                        style: "bordered",
                                                        minLength: 8,
                                                    }}
                                                />

                                                <Button
                                                    type="submit"
                                                    disabled={invalid}
                                                    title="Zmień hasło"
                                                />
                                            </form>
                                        )}
                                    />

                                    {this.state.error &&
                                    <div className="error">
                                        <div>{this.state.error.message}</div>
                                    </div>
                                    }
                                </div>
                                }

                                {this.state.isPasswordChanged &&
                                <ButtonLink to='/login' title="Przejdź do logowania"/>
                                }

                                <Snackbar
                                    className="success"
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    open={this.state.showSnackbar}
                                    autoHideDuration={2000}
                                    onClose={this.handleSnackbarClose}
                                    message={<span>Twoje hasło zostało zmienione!</span>}
                                />
                            </FormWrapper>
                        </Wrapper>
                    )}
                />
            );
        }
        return null;
    }
}

export default ChangePasswordScreen;
